<template>
  <a-modal
    :visible="visible"
    :title="title"
    @ok="handleSubmit"
    @cancel="close"
    width="800px"
    :confirmLoading="processing">
    <a-form-model
      :model="form"
      ref="form"
      :rules="rules"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="菜单名称" prop="name">
        <a-input v-model="form.name" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="上级菜单" prop="parentId">
        <a-tree-select
          v-model="form.parentId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="menuTree"
          :replaceFields="replaceFields"
          placeholder="Please select"
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="菜单URL" prop="url">
        <a-input v-model="form.url" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="权限" prop="perms">
        <a-input v-model="form.perms" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="菜单类型" prop="type">
        <a-select v-model="form.type">
          <a-select-option v-for="item in dict.menu_type" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="菜单图标" prop="icon">
        <a-input v-model="form.icon" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort">
        <a-input v-model="form.sort" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="组件" prop="component">
        <a-input v-model="form.component" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="路由名称" prop="routeName">
        <a-input v-model="form.routeName" style="width: 370px;" />
      </a-form-model-item>
      <a-form-model-item label="是否显示" prop="isShow">
        <a-radio-group :default-value="true" button-style="solid" v-model="form.isShow">
          <a-radio-button v-for="(item,i) in dict.is_show" :value="Boolean(parseInt(item.value))" :key="i">
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="路由重定向" prop="redirect">
        <a-input v-model="form.redirect" style="width: 370px;" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { menuAdd, menuEdit, menuDetail } from '@/api/employmentUser/menu'
import { mapGetters } from 'vuex'
import { PROCESSING } from '@/store/mutation-types'

export default {
  name: 'MenuForm',
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      id: '',
      replaceFields: {
        title: 'name', key: 'id', value: 'id'
      },
      form: {
        name: null,
        parentId: null,
        url: null,
        perms: null,
        type: null,
        icon: null,
        sort: null,
        component: null,
        routeName: null,
        isShow: null,
        redirect: null,
        clientId:'enterprise'
      },
      rules: {
        name: [
          { required: true, message: '请填写菜单名称', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请填写菜单URL', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请填写类型', trigger: 'blur' }
        ],
        component: [
          { required: true, message: '请填写组件', trigger: 'blur' }
        ],
        routeName: [
          { required: true, message: '请填写路由名称', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default () {
       return false
      }
    },
    menuTree: {
      type: Array,
      default () {
        return [false]
      }
    }
  },
  computed: {
    title () {
      return (this.id ? '编辑' : '新增') + '菜单'
    },
    ...mapGetters(['processing']),
    ...mapGetters('employmentSupport/dict', ['dict'])
  },
  methods: {
    init (id) {
      this.id = id
      const _self = this
      this.$nextTick(function () {
        _self.$refs.form.resetFields()
        if (id) {
          _self.getDetail(id)
        }
      })
    },
    getDetail (id) {
      this.$store.commit(PROCESSING, true)
      menuDetail(id).then(res => {
        this.form = res.data
        this.$store.commit(PROCESSING, false)
      })
    },
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$store.commit(PROCESSING, true)
          const submit = this.id ? menuEdit : menuAdd
          this.form.clientId ='enterprise'
          submit(this.form).then(res => {
            this.$store.commit(PROCESSING, false)
            if (res.success) {
              this.$emit('success')
              this.$message.success('保存成功')
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
    close () {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped>

</style>
